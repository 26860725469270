<template>
  <Wrapper class="subpage page-team">
    <div>
      <Titlebar :isLoading="!members">
        <div slot="title">
          <h5 class="title is-5 is-marginless">
            <span>{{ $t('members.title') }}</span>
            <small v-if="members !== null && members.length > 0" class="push-left-xs has-text-primary">{{ members.length }}st</small>
          </h5>
        </div>
      </Titlebar>
    </div>

    <div class="default-spacing is-paddingless-top">
      <MembersPageToolbar @search="onSearchInput" />

      <InlineLoader v-if="members === null" dark />

      <div v-else-if="members.length > 0">
        <MembersGridView
          v-if="view === 'grid'"
          :members="members"
          @remove="onRemoveMember"
          @removeInvite="onRemoveInvite">
        </MembersGridView>

        <MembersListView
          v-if="view === 'list'"
          :members="members"
          @remove="onRemoveMember"
          @removeInvite="onRemoveInvite">
        </MembersListView>
      </div>

      <Paragraph v-else :text="$t('members.noMembersMatchedSearched')" />
    </div>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex'

import MembersPageToolbar from './MembersPageToolbar.vue'
import MembersGridView from './MembersGridView.vue'
import MembersListView from './MembersListView.vue'

export default {
  components: {
    MembersPageToolbar,
    MembersGridView,
    MembersListView
  },

  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      searchText: ''
    }
  },

  computed: {
    ...mapGetters({
      invites: 'huntarea/members/invites/invites',
      isAdmin: 'huntarea/members/isAdmin',
      view: 'huntarea/members/view'
    }),

    members () {
      let members = this.$store.getters['huntarea/members/members']

      if (members !== null) {
        members = members.filter(member => member.getFullName().toLowerCase().indexOf(this.searchText) !== -1)
        return members
      }

      return null
    }
  },

  beforeMount () {
    this.$store.dispatch('huntarea/members/readLocalView')
  },

  methods: {
    onSearchInput (value) {
      this.searchText = value
    },

    async onRemoveMember (member) {
      try {
        const response = await this.$dialog.confirm({
          title: this.$t('members.removeMemberTitle'),
          message: this.$t('members.removeMemberConfirmMessage', { name: member.getFullName() }),
          ok: this.$t('general.delete')
        })

        if (response.ok) this.removeMember(member)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('members.removeMemberFailedErrorMessage'))
        console.error(error)
      }
    },

    removeMember (member) {
      return this.$store.dispatch('huntarea/members/delete', {
        huntAreaId: this.huntAreaId,
        userId: member.id
      })
    },

    async onRemoveInvite (invite) {
      try {
        const response = await this.$dialog.confirm({
          title: this.$t('members.removeInviteTitle'),
          message: this.$t('members.removeInviteConfirmMessage', { email: invite.email }),
          ok: this.$t('general.delete')
        })

        if (response.ok) {
          await this.removeInvite(invite)
        }
      } catch (error) {
        this.$notification.danger(this.$i18n.t('members.removeInviteFailedErrorMessage'))
        console.error(error)
      }
    },

    removeInvite (invite) {
      return this.$store.dispatch('huntarea/members/invites/delete', {
        huntAreaId: this.huntAreaId,
        inviteId: invite.id
      })
    }
  }
}
</script>

<style lang="scss">
.page-team {
  padding: 0;
  display: flex;
  flex-direction: column;
}
</style>
