import { render, staticRenderFns } from "./MembersListView.vue?vue&type=template&id=ddae8156&scoped=true&"
import script from "./MembersListView.vue?vue&type=script&lang=js&"
export * from "./MembersListView.vue?vue&type=script&lang=js&"
import style0 from "./MembersListView.vue?vue&type=style&index=0&id=ddae8156&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddae8156",
  null
  
)

export default component.exports