<template>
  <Fragment>
    <Table scrollable fullwidth hoverable>
      <TableHeader>
        <TableHeaderCell />
        <TableHeaderCell>{{ $t('general.name') }}</TableHeaderCell>
        <TableHeaderCell>{{ $t('general.email') }}</TableHeaderCell>
        <TableHeaderCell class="is-hidden-mobile">{{ $t('general.phoneNumber') }}</TableHeaderCell>
        <TableHeaderCell />
        <TableHeaderCell />
      </TableHeader>

      <TableContent>
        <TableRow v-for="member in members" :key="member.id">
          <TableCell class="is-narrow has-image">
            <ProfilePicture :userId="Number(member.id)" size="small" round />
          </TableCell>

          <TableCell class="is-narrow">
            {{ member.getFullName() }}
          </TableCell>

          <TableCell class="is-narrow">
            <a :href="`mailto: ${member.email}`" target="_blank">{{ member.email }}</a>
          </TableCell>

          <TableCell class="is-narrow is-hidden-mobile">
            {{ member.phoneNumber }}
          </TableCell>

          <TableCell class="is-narrow">
            <Badge
              v-if="member.role === 'admin'"
              type="primary"
              class="push-right-xs"
              :text="$t('general.admin')">
            </Badge>
          </TableCell>

          <TableCell class="has-text-right">
            <InlineLoader v-if="member.status === 'loading'" size="small" />

            <a v-else-if="canRemove(member)" @click="remove(member)">
              <Icon name="icon-trash" />
            </a>
          </TableCell>
        </TableRow>
      </TableContent>
    </Table>

    <div v-if="isAdmin && hasInvites">
      <Heading size="5" :text="$t('general.invited')" />

      <Table scrollable fullwidth hoverable>
        <TableHeader>
          <TableHeaderCell>
            {{ $t('general.email') }}
          </TableHeaderCell>

          <TableHeaderCell />
          <TableHeaderCell />
        </TableHeader>

        <TableContent>
          <TableRow v-for="invite in invites" :key="invite.id">
            <TableCell class="is-narrow">
              {{ invite.email }}
            </TableCell>

            <TableCell class="is-narrow">
              <Badge type="light" :text="$t('members.inviteAwaitingAnswer')" />
            </TableCell>

            <TableCell class="has-text-right">
              <a @click="removeInvite(invite)">
                <Icon name="icon-trash" />
              </a>
            </TableCell>
          </TableRow>
        </TableContent>
      </Table>
    </div>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    members: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      invites: 'huntarea/members/invites/invites',
      isAdmin: 'huntarea/members/isAdmin'
    }),

    hasInvites () {
      return this.invites && this.invites.length > 0
    }
  },

  methods: {
    canRemove (member) {
      const userId = this.$store.getters['auth/getUserId']
      return this.isAdmin && member.id !== userId
    },

    remove (member) {
      this.$emit('remove', member)
    },

    removeInvite (invite) {
      this.$emit('removeInvite', invite)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  padding: 0;
  overflow: hidden;
}
</style>
