<template>
  <div>
    <Toolbar>
      <ToolbarItemGroup align="left">
        <ToolbarItem>
          <Button
            type="secondary"
            :title="$t('general.add')"
            icon="icon-plus"
            :disabled="members === null"
            @click="onInvite">
          </Button>
        </ToolbarItem>
      </ToolbarItemGroup>

      <ToolbarItemGroup align="right">
        <ToolbarItem>
          <SearchInputField
            v-model="searchText"
            @input="onSearchInput">
          </SearchInputField>
        </ToolbarItem>

        <ToolbarItem class="is-hidden-touch">
          <ButtonGroup segmented>
            <Button
              :tooltip="$t('general.grid')"
              icon="icon-grid"
              @click="onChangeView('grid')">
            </Button>

            <Button
              :tooltip="$t('general.list')"
              icon="icon-align-justify"
              @click="onChangeView('list')">
            </Button>
          </ButtonGroup>
        </ToolbarItem>
      </ToolbarItemGroup>
    </Toolbar>

    <MembersInviteDialog
      v-if="inviteMembers"
      @confirm="onInviteConfirm"
      @close="inviteMembers = false">
    </MembersInviteDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MembersInviteDialog from './MembersInviteDialog.vue'

export default {
  components: {
    MembersInviteDialog
  },

  data () {
    return {
      inviteMembers: false,
      searchText: ''
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      members: 'huntarea/members/members'
    })

    /*
    searchText: {
      get () {
        return this.$store.getters['huntarea/members/search']
      },

      set (string) {
        this.$store.dispatch('huntarea/members/search', string)
      }
    }
    */
  },

  methods: {
    onChangeView (view) {
      this.$store.dispatch('huntarea/members/changeView', view)
    },

    onSearchInput (value) {
      this.$emit('search', value)
    },

    onInvite () {
      this.inviteMembers = true
    },

    onInviteConfirm (users) {
      for (const user of users) {
        let promise = user.id !== null ? this.inviteExistingUser(user) : this.inviteExternalUser(user)
        promise.catch((error) => {
          this.$notification.danger(this.$i18n.t('members.inviteFailedErrorMessage'))
          console.error(error)
        })
      }
    },

    inviteExistingUser (user) {
      return this.$store.dispatch('huntarea/members/create', user)
    },

    inviteExternalUser (user) {
      return this.$store.dispatch('huntarea/members/invites/create', user.email)
    }
  }
}
</script>
