<template>
  <UserSearchDialog
    :title="$t('members.inviteTitle')"
    :description="$t('members.inviteDescription')"
    :confirm-button-title="confirmButtonTitle"
    :selected="selected"
    :not-found-selectable="true"
    :not-found-text="$t('members.inviteAccountNotFoundMessage')"
    @selection-changed="onSelectionChanged"
    @confirm="onConfirm"
    @close="onClose">
  </UserSearchDialog>
</template>

<script>
import { mapGetters } from 'vuex'

import UserSearchDialog from '@/modules/user/components/UserSearchDialog.vue'
import UserModel from '@/modules/user/models/userModel'

export default {
  components: {
    UserSearchDialog
  },

  data () {
    return {
      selectedUsers: []
    }
  },

  computed: {
    ...mapGetters({
      members: 'huntarea/members/members',
      invites: 'huntarea/members/invites/invites'
    }),

    selected () {
      const members = this.members || []
      const invites = this.invites || []

      const invitedUsers = invites.map(invite => new UserModel({
        email: invite.email
      }))

      return [
        ...members,
        ...invitedUsers
      ]
    },

    confirmButtonTitle () {
      return this.$i18n.tc('members.inviteButtonTitle', this.selectedUsers.length, {
        userCount: this.selectedUsers.length
      })
    }
  },

  methods: {
    onClose () {
      this.close()
    },

    onConfirm (users) {
      this.$emit('confirm', users)
      this.close()
    },

    onSelectionChanged (users) {
      this.selectedUsers = users
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
