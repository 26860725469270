<template>
  <Fragment>
    <div class="columns is-multiline">
      <div
        v-for="member in members"
        :key="member.id"
        class="team-member column is-one-third">
        <div class="box">
          <div class="media">
            <div class="media-left">
              <ProfilePicture
                :userId="Number(member.id)"
                :name="member.getFullName()"
                size="small"
                round />
            </div>

            <div class="media-content">
              <p class="title is-5 is-marginless">{{ member.getFullName() }}</p>
              <a :href="`mailto: ${member.email}`" target="_blank">{{ member.email || '&nbsp;' }}</a>
              <p class="is-marginless">{{ member.phoneNumber || '&nbsp;' }}</p>
              <span v-if="member.role === 'admin'" class="tag is-primary is-small">{{ $t('general.admin') }}</span>
              <span v-else class="tag is-small">{{ $t('general.member') }}</span>
            </div>

            <div class="media-right">
              <InlineLoader v-if="member.status === 'loading'" size="small" />

              <Button
                v-else-if="canRemove(member)"
                type="white"
                size="small"
                icon="icon-trash"
                class="has-text-danger"
                @click="onRemove(member)">
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isAdmin && hasInvites">
      <h5 class="title is-5">
        {{ $t('general.invited') }}
      </h5>

      <div class="columns is-multiline">
        <div
          v-for="invite in invites"
          :key="invite.id"
          :class="{ 'is-dimmed': invite.status === 'delete' }"
          class="team-member column is-one-third">
          <div class="box">
            <div class="media">
              <div class="media-content">
                <p class="title is-5 is-marginless">{{ invite.email }}</p>
                <Badge type="light" :text="$t('members.inviteAwaitingAnswer')" />
              </div>

              <div class="media-right">
                <button class="button is-white is-small" @click="onRemoveInvite(invite)">
                  <Icon name="icon-trash" class="has-text-danger" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    members: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      invites: 'huntarea/members/invites/invites',
      isAdmin: 'huntarea/members/isAdmin'
    }),

    hasInvites () {
      return this.invites && this.invites.length > 0
    }
  },

  methods: {
    canRemove (member) {
      const userId = this.$store.getters['auth/getUserId']
      return this.isAdmin && member.id !== userId
    },

    onRemove (member) {
      this.$emit('remove', member)
    },

    onRemoveInvite (invite) {
      this.$emit('removeInvite', invite)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
}

.media {
  white-space: nowrap;
  overflow: hidden;

  .media-content {
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      margin-left: -40px;
      width: 40px;
      height: 100%;
      top: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }
}
</style>
